<a
  class="sub-btn filter-btn"
  data-bs-toggle="offcanvas"
  href="#offcanvasExample"
  role="button"
  aria-controls="offcanvasExample"
  >Filters</a
>

<div
  class="offcanvas offcanvas-start row"
  tabindex="-1"
  id="offcanvasExample"
  aria-labelledby="offcanvasExampleLabel"
>
  <div class="col-sm-12 filter">
    <div class="offcanvas-body">
      <div class="row">
        <div class="col-12 mb-2">
          <button
            type="button"
            class="btn-close text-reset"
            data-bs-dismiss="offcanvas"
            aria-label="Close"
          ></button>
        </div>
        <div class="col-12 mb-2">
          <div class="row">
            <div class="col-6">
              <h4>Filters</h4>
            </div>
            <div class="col-6 text-right">
              <h5>x Clear all</h5>
            </div>
          </div>
        </div>
      </div>

      <div class="row">
        <div
          class="col-sm-12"
          (click)="toggleTag(1)"
          style="
            cursor: pointer;
            padding: 0 15px;
            position: relative;
            padding-bottom: 15px;
          "
        >
          <h6>Type</h6>
        </div>
        <div class="col-sm-12">
          <ng-container *ngIf="!showToggle[1]">
            <div class="row form">
              <div class="col-lg-12 col-md-12">
                <input
                  id="buy"
                  class="filter-change checkbox filter"
                  name="land"
                  type="checkbox"
                  value="animal"
                  (change)="eventCheck($event)"
                />
                <label class="filter-label field" for="buy">
                  <img
                    src="assets/images/field-diamond.svg"
                    class="filter-diamond-icon"
                  />Genesis</label
                >

                <input
                  id="auction"
                  class="filter-change checkbox filter"
                  name="land"
                  type="checkbox"
                  value="game"
                  (change)="eventCheck($event)"
                />
                <label class="filter-label hill" for="auction"
                  ><img
                    src="assets/images/hill-diamond.svg"
                    class="filter-diamond-icon"
                  />Equipment</label
                >
                <input
                  id="list"
                  class="filter-change checkbox filter"
                  name="land"
                  type="checkbox"
                  value="listed"
                  (change)="eventCheck($event)"
                />
                <label class="filter-label hill" for="list"
                  ><img
                    src="assets/images/river-diamond.svg"
                    class="filter-diamond-icon"
                  />Listed</label
                >

                <!-- <input id="new" class="filter-change checkbox filter" name="land" type="checkbox" value="new" />
						  	<label class="filter-label mountain" for="new"><img src="assets/images/mountain-diamond.svg" class="filter-diamond-icon" />mountain</label>

						  	<input id="offers" class="filter-change checkbox filter" name="smoke" type="checkbox" value="offers" />
						  	<label class="filter-label river" for="offers"><img src="assets/images/river-diamond.svg" class="filter-diamond-icon" />river</label> -->
              </div>
            </div>
          </ng-container>
        </div>
      </div>

      <div class="row">
        <div
          class="col-sm-12"
          (click)="toggleTag(2)"
          style="
            cursor: pointer;
            padding: 0 15px;
            position: relative;
            padding-bottom: 15px;
          "
        >
          <h6>Price</h6>
        </div>
        <div class="col-sm-12">
          <ng-container *ngIf="!showToggle[2]">
            <div class="row form">
              <div class="col-lg-12">
                <div class="custom-slider">
                  <ngx-slider
                    [(value)]="minValue"
                    [(highValue)]="maxValue"
                    [options]="options"
                    (userChangeEnd)="getEvent($event)"
                  ></ngx-slider>
                </div>
              </div>
            </div>
          </ng-container>
        </div>
      </div>

      <!-- <div class="row">
        <div
          class="col-sm-12"
          (click)="toggleTag(3)"
          style="
            cursor: pointer;
            padding: 0 15px;
            position: relative;
            padding-bottom: 15px;
          "
        >
          <h6>Factions stats</h6>
        </div>
        <div class="col-sm-12">
          <ng-container *ngIf="!showToggle[3]">
            <div class="row form">
              <div class="col-lg-12">
                <div class="custom-slider">
                  <ngx-slider
                    [(value)]="minValue"
                    [(highValue)]="maxValue"
                    [options]="options"
                  ></ngx-slider>
                </div>
              </div>
            </div>
          </ng-container>
        </div>
      </div> -->

      <!--<div class="row">
			<div class="col-sm-12" (click)="toggleTag(3)"  style="cursor:  pointer ;padding: 0 15px; position: relative; padding-bottom: 15px" >
				<h5>Collections</h5>
				<img src="{{ !showToggle[3] == true ? 'assets/images/up.svg' : 'assets/images/down.svg' }}" width="15px" style="position: absolute;  top:20px; right: 10px">
			</div>
			<div class="col-sm-12">
				<ng-container *ngIf="!showToggle[3]">
					<div class="row form">
						<div class="col-lg-12">
							<div class="search-box d-flex" style="margin-bottom: 10px">
			        	<input class="form-control" type="search" placeholder="Filter" aria-label="Search">
			        	<button class="btn" type="submit"><fa-icon  [icon]="faSearch" style="color: #333"></fa-icon></button>
			        </div>
						</div>
						<div class="col-lg-12">
							<div class="collection-box">
								<div  class="custom-control custom-checkbox collection-filter-checkbox ng-star-inserted">
									<input type="checkbox" class="custom-control-input" value="art" id="art">
									<label class="custom-control-label" for="art">Art</label>
								</div>
								<div  class="custom-control custom-checkbox collection-filter-checkbox ng-star-inserted">
									<input type="checkbox" class="custom-control-input" value="music" id="music">
									<label class="custom-control-label" for="music">Music</label>
								</div>
								<div  class="custom-control custom-checkbox collection-filter-checkbox ng-star-inserted">
									<input type="checkbox" class="custom-control-input" value="education" id="education">
									<label class="custom-control-label" for="education">Education</label>
								</div>
								<div  class="custom-control custom-checkbox collection-filter-checkbox ng-star-inserted">
									<input type="checkbox" class="custom-control-input" value="collectibles" id="collectibles">
									<label class="custom-control-label" for="collectibles">Collectibles</label>
								</div>
								<div  class="custom-control custom-checkbox collection-filter-checkbox ng-star-inserted">
									<input type="checkbox" class="custom-control-input" value="card" id="card">
									<label class="custom-control-label" for="card">Trading Card</label>
								</div>
								<div  class="custom-control custom-checkbox collection-filter-checkbox ng-star-inserted">
									<input type="checkbox" class="custom-control-input" value="sports" id="sports">
									<label class="custom-control-label" for="sports">Sports</label>
								</div>
							</div>
						</div>
					</div>
				</ng-container>
			</div>
		</div>


		<div class="row">
			<div class="col-sm-12" (click)="toggleTag(4)"  style="cursor:  pointer ;padding: 0 15px; position: relative; padding-bottom: 15px" >
				<h6>Chains</h6>
				<img src="{{ !showToggle[1] == true ? 'assets/images/up.svg' : 'assets/images/down.svg' }}" width="15px" style="position: absolute;  top:25px; left: 10px">
			</div>
			<div class="col-sm-12">
				<ng-container *ngIf="!showToggle[4]">
					<div class="row form">
						<div class="col-lg-12">
							<input id="buy" class="filter-change checkbox filter" name="smoke" type="checkbox" value="buy" />
						  	<label class="filter-label" for="buy">Buy Now</label>


							<input id="auction" class="filter-change checkbox filter" name="smoke" type="checkbox" value="auction" />
						  	<label class="filter-label" for="auction">On Auction</label>

						  	<input id="new" class="filter-change checkbox filter" name="smoke" type="checkbox" value="new" />
						  	<label class="filter-label" for="new">New</label>

						  	<input id="offers" class="filter-change checkbox filter" name="smoke" type="checkbox" value="offers" />
						  	<label class="filter-label" for="offers">Offers</label>
						</div>
					</div>
				</ng-container>
			</div>
		</div>


		<div class="row">
			<div class="col-sm-12" (click)="toggleTag(5)"  style="cursor:  pointer ;padding: 0 15px; position: relative; padding-bottom: 15px" >
				<h6>Categories</h6>
				<img src="{{ !showToggle[1] == true ? 'assets/images/up.svg' : 'assets/images/down.svg' }}" width="15px" style="position: absolute;  top:25px; left: 10px">
			</div>
			<div class="col-sm-12">
				<ng-container *ngIf="showToggle[5]">
					<div class="row form">
						<div class="col-lg-12">
							<div class="collection-box">
								<div  class="custom-control custom-checkbox collection-filter-checkbox ng-star-inserted">
									<input type="checkbox" class="custom-control-input" value="art" id="art">
									<label class="custom-control-label" for="art">Art</label>
								</div>
								<div  class="custom-control custom-checkbox collection-filter-checkbox ng-star-inserted">
									<input type="checkbox" class="custom-control-input" value="music" id="music">
									<label class="custom-control-label" for="music">Music</label>
								</div>
								<div  class="custom-control custom-checkbox collection-filter-checkbox ng-star-inserted">
									<input type="checkbox" class="custom-control-input" value="education" id="education">
									<label class="custom-control-label" for="education">Education</label>
								</div>
								<div  class="custom-control custom-checkbox collection-filter-checkbox ng-star-inserted">
									<input type="checkbox" class="custom-control-input" value="collectibles" id="collectibles">
									<label class="custom-control-label" for="collectibles">Collectibles</label>
								</div>
								<div  class="custom-control custom-checkbox collection-filter-checkbox ng-star-inserted">
									<input type="checkbox" class="custom-control-input" value="card" id="card">
									<label class="custom-control-label" for="card">Trading Card</label>
								</div>
								<div  class="custom-control custom-checkbox collection-filter-checkbox ng-star-inserted">
									<input type="checkbox" class="custom-control-input" value="sports" id="sports">
									<label class="custom-control-label" for="sports">Sports</label>
								</div>
							</div>
						</div>
					</div>
				</ng-container>
			</div>
		</div>


		<div class="row">
			<div class="col-sm-12" (click)="toggleTag(6)"  style="cursor:  pointer ;padding: 0 15px; position: relative; padding-bottom: 15px" >
				<h6>On Sales In</h6>
				<img src="{{ !showToggle[1] == true ? 'assets/images/up.svg' : 'assets/images/down.svg' }}" width="15px" style="position: absolute;  top:25px; left: 10px">
			</div>
			<div class="col-sm-12">
				<ng-container *ngIf="showToggle[6]">
					<div class="row form">
						<div class="col-lg-12">
								<input id="btc" class="filter-change checkbox filter" name="sell" type="checkbox" value="btc" />
						  	<label class="filter-label" for="btc">BTC</label>

								<input id="eth" class="filter-change checkbox filter" name="sell" type="checkbox" value="eth" />
						  	<label class="filter-label" for="eth">ETH</label>

						  	<input id="xrp" class="filter-change checkbox filter" name="sell" type="checkbox" value="xrp" />
						  	<label class="filter-label" for="xrp">xrp</label>

						  	<input id="ltc" class="filter-change checkbox filter" name="sell" type="checkbox" value="ltc" />
						  	<label class="filter-label" for="ltc">LTC</label>

						  	<input id="bch" class="filter-change checkbox filter" name="sell" type="checkbox" value="bch" />
						  	<label class="filter-label" for="offers">BCH</label>
						</div>
					</div>
				</ng-container>
			</div>
		</div>
	-->
    </div>
  </div>
</div>
