<app-navbar></app-navbar>
<section  style="margin-top: 130px">
	<div class="container">
		<div class="row">
			<div class="col-sm-9">
    			<article>
					<div class="news-box">
						<img src="assets/images/2.jpg" style="min-height: 500px;">
						<div>
							<h5 class="detail-owner-text ms-0">Category</h5>
							<h3 class="detail-tab-title mb-1">Lorem ipsum dolor sit ame</h3>
							<fa-icon [icon]="faClock" style="margin-right: 10px;"></fa-icon>Jan 1
							
						</div>
						<div class="content">
							<p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.</p>
							<p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.</p>
							<p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.</p>
							<p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.</p>
						</div>
						<div>
						</div>
					</div>
				</article>
			</div>	
			<div class="col-sm-3">
				<div class="row">
					<div class="col-12">
						<h3 class="mb-2">Recent Post</h3>
					</div>
				</div>
				<div class="row"> 
					<div class="col-12" style="margin-bottom: 30px;">
						<img src="assets/images/2.jpg" width="100%" style="border-radius: 15px; margin-bottom: 10px; min-height: 200px;">
						<h3 class="detail-tab-title mb-1">Lorem ipsum dolor sit ame</h3>
						<p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, labore et dolore magna aliqua. Ut enim ad minim veniam..</p>
					</div>
					<div class="col-12" style="margin-bottom: 30px;">
						<img src="assets/images/2.jpg" width="100%" style="border-radius: 15px; margin-bottom: 10px; min-height: 200px;">
						<h3 class="detail-tab-title mb-1">Lorem ipsum dolor sit ame</h3>
						<p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, labore et dolore magna aliqua. Ut enim ad minim veniam..</p>
					</div>
					<div class="col-12" style="margin-bottom: 30px;">
						<img src="assets/images/2.jpg" width="100%" style="border-radius: 15px; margin-bottom: 10px; min-height: 200px;">
						<h3 class="detail-tab-title mb-1">Lorem ipsum dolor sit ame</h3>
						<p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, labore et dolore magna aliqua. Ut enim ad minim veniam..</p>
					</div>
					<div class="col-12" style="margin-bottom: 30px;">
						<img src="assets/images/2.jpg" width="100%" style="border-radius: 15px; margin-bottom: 10px; min-height: 200px;">
						<h3 class="detail-tab-title mb-1">Lorem ipsum dolor sit ame</h3>
						<p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, labore et dolore magna aliqua. Ut enim ad minim veniam..</p>
					</div>
					<div class="col-12" style="margin-bottom: 30px;">
						<img src="assets/images/2.jpg" width="100%" style="border-radius: 15px; margin-bottom: 10px; min-height: 200px;">
						<h3 class="detail-tab-title mb-1">Lorem ipsum dolor sit ame</h3>
						<p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, labore et dolore magna aliqua. Ut enim ad minim veniam..</p>
					</div>

				</div>
			</div>
		</div>
	</div>
</section>