import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class SharedService {
  private subject = new Subject<any>();
  private subject2 = new Subject<any>();

  sendClickEvent(param) {
    this.subject.next(param);
  }

  sendSlideEvent(param) {
    this.subject2.next(param);
  }

  getClickEvent() {
    return this.subject.asObservable();
  }

  getSlideEvent() {
    return this.subject2.asObservable();
  }
}
