import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.css'],
})
export class ContactComponent implements OnInit {
  constructor() {}

  email_Inv = false;
  message_Inv = false;
  wallet_Inv = false;

  ngOnInit(): void {}

  submit(firstName, lastName, email, message, wallet) {
    if (email.match(/^\s*$/)) {
      console.log(9);
      this.email_Inv = true;
    }
    if (message.match(/^\s*$/)) {
      this.message_Inv = true;
    }
    if (wallet.match(/^\s*$/)) {
      this.wallet_Inv = true;
    }
  }
}
