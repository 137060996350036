import { Injectable } from '@angular/core';
import { Web3Provider } from '@ethersproject/providers';
import { BigNumber, Contract, ethers } from 'ethers';
import { environment } from 'src/environments/environment';
import { BlockchainService } from './blockchain.service';
import { WalletService } from './wallet.service';

declare let window: any;

@Injectable({
  providedIn: 'root',
})
export class NftContractService {
  provider: Web3Provider;
  signer: any;
  abi = environment.SVV_ABI.TOKEN;

  constructor(private bc: BlockchainService, private wallet: WalletService) {
    try {
      this.provider = new ethers.providers.Web3Provider(window.ethereum);
      this.signer = this.provider.getSigner();
    } catch (error) {}
  }

  // async checkEvents() {
  //   console.log("Listening to event");

  //   this.nftContract.on('ApprovalForAll', (owner, operator, approved) => {
  //     console.log(`Approval for all ${owner}, ${operator}, ${approved}`);
  //     /*use this event listener to update state into db,
  //     this allow background process and avoid long waiting at frontend,
  //     data will be received background once the event emitted from the smart contract*/
  //   })
  // }

  //Params: nft contract address, true
  setApproveAll(tokenAddress: string, status: boolean): Promise<any> {
    const contract = new ethers.Contract(
      tokenAddress,
      environment.SVV_ABI.IERC721,
      this.signer
    );
    return new Promise((resolve, reject) => {
      contract
        .setApprovalForAll(environment.SVV_MARKETPLACE_ADDRESS, status)
        .then((tx: any) => {
          tx.wait()
            .then((receipt: any) => {
              console.log(receipt);
              this.bc
                .getTimestamp(receipt.blockNumber)
                .then((time: any) => {
                  const data = {
                    hash: receipt.transactionHash,
                    owner: receipt.events[0].args[0].toLowerCase(),
                    operator: receipt.events[0].args[1].toLowerCase(),
                    status: receipt.events[0].args[2],
                    timestamp: time,
                  };
                  //use this data object to update to the nft API
                  resolve(data);
                })
                .catch((err: Error) => reject(err));
            })
            .catch((err: Error) => reject(err));
        })
        .catch((err: Error) => reject(err));
    });
  }

  //Param: nft contract address
  async isApprovedForAll(tokenAddress: string): Promise<string> {
    const contract = new ethers.Contract(
      tokenAddress,
      environment.SVV_ABI.IERC721,
      this.provider
    );
    const owner = await this.signer.getAddress();
    return new Promise<string>(async (resolve, reject) => {
      contract
        .isApprovedForAll(owner, environment.SVV_MARKETPLACE_ADDRESS)
        .then((res: any) => {
          resolve(res);
        })
        .catch((err: Error) => reject(err));
    });
  }
}
