<section style="margin-top: 10px">
  <div class="home-banner" style="background-image: url(assets/images/2.jpg)">
    <div class="container h-100">
      <div class="row h-100">
        <div class="col-sm-5 col-md-6 col-lg-5 my-auto">
          <div class="slider-content">
            <h1>Gas-Free Marketplace</h1>
            <h4>
              We offer a gas-free environment to trade your NFTs. Zero-worry..
              100% authentic.
            </h4>
          </div>
        </div>
        <div class="col-sm-5 col-md-6 col-lg-5 mx-auto my-auto">
          <img
            src="assets/images/nft-concept.jpg"
            width="100%"
            style="object-fit: cover"
          />
        </div>
      </div>
    </div>
  </div>
</section>

<section>
  <div class="container">
    <div class="row">
      <div class="col-sm-8 ms-auto me-auto text-center">
        <h3 class="title1">Help Center</h3>
        <p class="home-content"></p>
      </div>
    </div>
  </div>
</section>

<section>
  <div class="container">
    <!-- <div class="row">
      <div class="col-sm-10 ms-auto me-auto text-center">
        <h3 class="title1">How does our NFT work in our marketplace?</h3>
      </div>
    </div> -->
  </div>
  <div class="container-fluid">
    <div class="row">
      <div class="col-sm-6">
        <div class="p-5">
          <h4>Getting started</h4>
          <p>Learn how to create an account with your wallet.</p>
        </div>
      </div>
      <div class="col-sm-6">
        <img
          src="assets/images/nft-concept.jpg"
          width="100%"
          style="object-fit: cover; height: 100%"
        />
      </div>
    </div>
    <div class="row">
      <div class="col-sm-6">
        <img
          src="assets/images/nft-concept.jpg"
          width="100%"
          style="object-fit: cover; height: 100%"
        />
      </div>
      <div class="col-sm-6">
        <div class="p-5">
          <h4>Buying</h4>
          <p>
            Learn how to purchase your first NFT from WoWSpace and understand
            all the applied charges throughout the journey.
          </p>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-sm-6">
        <div class="p-5">
          <h4>Selling</h4>
          <p>Learn how to put your NFTs for sales.</p>
        </div>
      </div>
      <div class="col-sm-6">
        <img
          src="assets/images/nft-concept.jpg"
          width="100%"
          style="object-fit: cover; height: 100%"
        />
      </div>
    </div>
  </div>
</section>

<section>
  <div class="container">
    <div class="row">
      <div class="col-sm-12">
        <h3 class="title1">
          Resources information may help in your journey in NFT
        </h3>
      </div>
    </div>
    <div class="row">
      <div class="col-sm-6 col-lg-4">
        <img
          src="assets/images/art.jpg"
          width="100%"
          style="object-fit: cover; height: 250px"
        />
        <div class="blog-box p-3">
          <h5>Set up your wallet</h5>
          <!-- <p>
            Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do
            eiusmod tempor incididunt ut labore et dolore.
          </p> -->
        </div>
      </div>
      <div class="col-sm-6 col-lg-4">
        <img
          src="assets/images/art.jpg"
          width="100%"
          style="object-fit: cover; height: 250px"
        />
        <div class="blog-box p-3">
          <h5>Set up your wallet</h5>
          <!-- <p>
            Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do
            eiusmod tempor incididunt ut labore et dolore.
          </p> -->
        </div>
      </div>
      <div class="col-sm-6 col-lg-4">
        <img
          src="assets/images/art.jpg"
          width="100%"
          style="object-fit: cover; height: 250px"
        />
        <div class="blog-box p-3">
          <h5>Set up your wallet</h5>
          <!-- <p>
            Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do
            eiusmod tempor incididunt ut labore et dolore.
          </p> -->
        </div>
      </div>
      <div class="col-sm-6 col-lg-4">
        <img
          src="assets/images/art.jpg"
          width="100%"
          style="object-fit: cover; height: 250px"
        />
        <div class="blog-box p-3">
          <h5>Set up your wallet</h5>
          <!-- <p>
            Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do
            eiusmod tempor incididunt ut labore et dolore.
          </p> -->
        </div>
      </div>
      <div class="col-sm-6 col-lg-4">
        <img
          src="assets/images/art.jpg"
          width="100%"
          style="object-fit: cover; height: 250px"
        />
        <div class="blog-box p-3">
          <h5>Set up your wallet</h5>
          <!-- <p>
            Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do
            eiusmod tempor incididunt ut labore et dolore.
          </p> -->
        </div>
      </div>
      <div class="col-sm-6 col-lg-4">
        <img
          src="assets/images/art.jpg"
          width="100%"
          style="object-fit: cover; height: 250px"
        />
        <div class="blog-box p-3">
          <h5>Set up your wallet</h5>
          <!-- <p>
            Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do
            eiusmod tempor incididunt ut labore et dolore.
          </p> -->
        </div>
      </div>
    </div>
  </div>
</section>
