import {
  Component,
  OnInit,
  Input,
  ViewChild,
  TemplateRef,
} from '@angular/core';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import { Router } from '@angular/router';
import { UsersService } from '../services/users.service';
import { NftService } from '../services/nft.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { Md5 } from 'ts-md5';
import Swal from 'sweetalert2';
@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css'],
})
export class HomeComponent implements OnInit {
  @Input() tab: 'last24h' | '7days' | '30days' = 'last24h';
  @Input() tabListed: 'lifeListed' | 'landListed' = 'lifeListed';
  @Input() tabSold: 'lifeSold' | 'landSold' = 'lifeSold';
  @ViewChild('bind') contentreset: TemplateRef<any>;
  nftList;
  topNFT;
  tradingOverView;

  constructor(
    private modalService: NgbModal,
    private router: Router,
    private userService: UsersService,
    private nftService: NftService,
    private spinner: NgxSpinnerService
  ) {}

  async ngOnInit(): Promise<void> {
    await this.userService.checkMetaMask();
    await this.userService.getToken().subscribe((data) => {
      console.log(data.access_token);
      localStorage.setItem('access_token', data.access_token);
      if (data.access_token) {
        this.nftService.getAllAnimalNft().subscribe((data) => {
          this.nftList = data.result.slice(0, 4);
          this.topNFT = data.result[0];
        });
        //get trading over view
        this.nftService.tradingOverView().subscribe((data) => {
          if (data.success === true) {
            this.tradingOverView = data.result;
            console.log('overview', this.tradingOverView);
            console.log(
              'overview24.',
              this.tradingOverView.last_24h.total_sales
            );
          }
        });
      }
    });
  }

  ngAfterViewInit() {
    if (
      localStorage.getItem('account_address') !== null &&
      localStorage.getItem(
        'bindInfo' + localStorage.getItem('account_address')
      ) !== 'true'
    ) {
      this.modalService.open(this.contentreset, { centered: true });
      localStorage.setItem(
        'bindInfo' + localStorage.getItem('account_address'),
        'true'
      );
    }
  }

  open(content) {
    this.modalService.open(content, { size: 'm', centered: true });
  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }

  showQuickview(content) {
    this.modalService.open(content, { size: 'xl', centered: true });
  }

  product() {
    this.router.navigate(['product']);
  }

  submit(email, password) {
    this.spinner.show();
    if (email !== '' && password !== '') {
      let md5 = new Md5();
      let hashPassword = md5.appendAsciiStr(password).end();
      let data = {
        email: email,
        password: hashPassword,
        wallet_address: localStorage.getItem('account_address'),
      };
      this.userService.bindUser(data).subscribe((data) => {
        if (data.success) {
          this.spinner.hide();
          Swal.fire({
            title: 'Success!',
            text: 'Your game account is now linked to your wallet!',
            icon: 'success',
          });
          this.modalService.dismissAll('gameInfo');
        } else {
          console.log(1);
          this.spinner.hide();
          Swal.fire({
            title: 'Error!',
            text: 'Binding failed. Invalid game email or password. ',
            icon: 'error',
          });
        }
      });
    }
  }
}
