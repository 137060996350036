import { Component, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import {
  faSearch,
  faEye,
  faList,
  faTh,
} from '@fortawesome/free-solid-svg-icons';
import { faHeart } from '@fortawesome/free-regular-svg-icons';
import { NftService } from '../services/nft.service';
import { ScEventsService } from '../services/sc-events.service';
import { SharedService } from '../services/shared.service';
import { UsersService } from '../services/users.service';

@Component({
  selector: 'app-product',
  templateUrl: './product.component.html',
  styleUrls: ['./product.component.css'],
})
export class ProductComponent implements OnInit {
  clickEventSubscription: Subscription;
  slideEventSubscription: Subscription;
  faSearch = faSearch;
  faHeart = faHeart;
  faEye = faEye;
  faTh = faTh;
  faList = faList;
  isGrid = true;
  nftList;
  filterAnimalList;
  filterGameList;
  filterCount = 12;
  totalList;
  totalCount;
  animalFilterStat = false;
  gameFilterStat = false;
  listedFilterSat = false;
  errMsg;
  constructor(
    private modalService: NgbModal,
    private router: Router,
    private nftService: NftService,
    // private marketPlace: MarketplaceService,
    // private nftContract: NftContractService,
    private contractEvent: ScEventsService,
    private sharedService: SharedService,
    private userService: UsersService
  ) {
    this.clickEventSubscription = this.sharedService
      .getClickEvent()
      .subscribe(async (data) => {
        if (data.value === 'animal') {
          this.animalFilterStat = data.status;
        } else if (data.value === 'game') {
          this.gameFilterStat = data.status;
        } else if (data.value === 'listed') {
          this.listedFilterSat = data.status;
        }
        this.filterNftByType();
      });

    this.clickEventSubscription = this.sharedService
      .getSlideEvent()
      .subscribe(async (data) => {
        await this.filterNftByPrice(data.value, data.highValue);
      });
  }

  async ngOnInit(): Promise<void> {
    try {
      this.userService.checkMetaMask();

      // Get animal nft available in marketplace
      await this.nftService.getAllAnimalNft().subscribe((data) => {
        if (data.success && data.result.length > 0) {
          this.totalList = data.result;
        }
      });
      await this.nftService.getAllGameNft().subscribe((data) => {
        if (data.success && data.result.length > 0) {
          this.totalList = this.totalList.concat(data.result);
          this.totalList.sort((a, b) => b.price - a.price);
          this.nftList = this.totalList.slice(0, this.filterCount);
          this.totalCount = this.totalList.length;
          this.sortBy(1);
          console.log('total', this.totalList);
        }
      });
    } catch (e) {}
  }

  onScroll() {
    console.log('scrolled!!');
    if (this.filterCount <= this.totalList?.length) {
      this.filterCount += 10;
      this.nftList = this.totalList.slice(0, this.filterCount);
    }
  }
  showQuickview(content) {
    this.modalService.open(content, { size: 'xl', centered: true });
  }

  open(content, size) {
    this.modalService.open(content, { size: size, centered: true });
  }

  productDetail() {
    this.router.navigate(['product/detail']);
  }

  product() {
    this.router.navigate(['product/detail']);
  }

  gridRow(value) {
    if (value == 1) {
      this.isGrid = true;
    } else {
      this.isGrid = false;
    }
  }

  sortBy(value) {
    this.filterCount = 12;

    if (value == 1) {
      //this.totalList.sort((a, b) => b.price - a.price);
      this.totalList = this.totalList
        .filter((e) => e.price > 0)
        .sort(
          (a, b) =>
            new Date(b.updated_at).getTime() - new Date(a.updated_at).getTime()
        )
        .concat(this.totalList.filter((e) => e.price == 0));
    } else if (value == 2) {
      this.totalList.sort(
        (a, b) =>
          new Date(b.created_at).getTime() - new Date(a.created_at).getTime()
      );
    } else if (value == 3) {
      this.totalList = this.totalList
        .filter((e) => e.price > 0 && e.isListed)
        .sort((a, b) => a.price - b.price)
        .concat(this.totalList.filter((e) => e.price == 0 || !e.isListed));
    } else if (value == 4) {
      this.totalList = this.totalList
        .filter((e) => e.price > 0 && e.isListed)
        .sort((a, b) => b.price - a.price)
        .concat(this.totalList.filter((e) => e.price == 0 || !e.isListed));
    }
    console.log('totalList', this.totalList);
    this.nftList = this.totalList.slice(0, this.filterCount);
  }

  async filterNftByType() {
    this.filterCount = 12;
    this.totalList = null;
    this.nftList = null;

    if (this.animalFilterStat && !this.gameFilterStat) {
      await this.nftService.getAllAnimalNft().subscribe((data) => {
        if (data.success && data.result.length > 0) {
          this.totalList = data.result;
          if (this.listedFilterSat) {
            this.totalList = this.totalList
              .filter((e) => e.price > 0 && e.isListed)
              .sort((a, b) => b.price - a.price);
          } else {
            this.totalList.sort((a, b) => b.price - a.price);
          }
          this.nftList = this.totalList.slice(0, this.filterCount);
          this.totalCount = this.totalList.length;
        }
      });
    } else if (!this.animalFilterStat && this.gameFilterStat) {
      await this.nftService.getAllGameNft().subscribe((data) => {
        if (data.success && data.result.length > 0) {
          this.totalList = data.result;
          if (this.listedFilterSat) {
            this.totalList = this.totalList
              .filter((e) => e.price > 0 && e.isListed)
              .sort((a, b) => b.price - a.price);
          } else {
            this.totalList.sort((a, b) => b.price - a.price);
          }
          this.nftList = this.totalList.slice(0, this.filterCount);
          this.totalCount = this.totalList.length;
        }
      });
    } else {
      await this.nftService.getAllAnimalNft().subscribe((data) => {
        if (data.success && data.result.length > 0) {
          this.totalList = data.result;
        }
      });
      await this.nftService.getAllGameNft().subscribe((data) => {
        if (data.success && data.result.length > 0) {
          this.totalList = this.totalList.concat(data.result);
          if (this.listedFilterSat) {
            this.totalList = this.totalList
              .filter((e) => e.price > 0 && e.isListed)
              .sort((a, b) => b.price - a.price);
          } else {
            this.totalList.sort((a, b) => b.price - a.price);
          }
          this.nftList = this.totalList.slice(0, this.filterCount);
          this.totalCount = this.totalList.length;
          console.log('total', this.totalList);
        }
      });
    }
  }

  async filterNftByPrice(min, max) {
    this.filterCount = 12;
    this.totalList = null;
    this.nftList = null;
    try {
      if (this.animalFilterStat && !this.gameFilterStat) {
        await this.nftService.getAllAnimalNft().subscribe((data) => {
          if (data.success && data.result.length > 0) {
            this.totalList = data.result;
            this.totalList.sort((a, b) => b.price - a.price);
            this.totalList = this.totalList.filter((item) => {
              return item.price >= min && item.price <= max;
            });

            this.nftList = this.totalList.slice(0, this.filterCount);
            this.totalCount = this.totalList.length;
          }
        });
      } else if (!this.animalFilterStat && this.gameFilterStat) {
        await this.nftService.getAllGameNft().subscribe((data) => {
          if (data.success && data.result.length > 0) {
            this.totalList = data.result;
            this.totalList.sort((a, b) => b.price - a.price);
            this.totalList = this.totalList.filter((item) => {
              return item.price >= min && item.price <= max;
            });

            this.nftList = this.totalList.slice(0, this.filterCount);
            this.totalCount = this.totalList.length;
          }
        });
      } else {
        await this.nftService.getAllAnimalNft().subscribe((data) => {
          if (data.success && data.result.length > 0) {
            this.totalList = data.result;
          }
        });
        await this.nftService.getAllGameNft().subscribe((data) => {
          if (data.success && data.result.length > 0) {
            this.totalList = this.totalList.concat(data.result);
            this.totalList.sort((a, b) => b.price - a.price);
            this.totalList = this.totalList.filter((item) => {
              return item.price >= min && item.price <= max;
            });

            this.nftList = this.totalList.slice(0, this.filterCount);
            this.totalCount = this.totalList.length;
          }
        });
      }
    } catch (e) {}
  }

  async searchNft(key) {
    console.log('tr', key.trim().length);

    if (key.trim().length > 0) {
      this.modalService.dismissAll('search');
      var animalResult;
      var gameResult;
      var param = {
        param: key,
      };
      await this.nftService.findAnimal(param).subscribe(async (data) => {
        console.log(data);
        if (data.success) {
          animalResult = data.result;
        }
        await this.nftService.findEquipment(key).subscribe((data) => {
          console.log(data);
          if (data.success) {
            gameResult = data.result;
          }
          if (animalResult) {
            this.totalList = animalResult;
            this.totalList = this.totalList.concat(gameResult);
          } else {
            this.totalList = gameResult;
          }
          this.nftList = this.totalList;
          this.totalCount = this.nftList.length;
        });
      });
    }
  }
}
