import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { MarketplaceService } from '../../services/marketplace.service';
import { NftService } from '../../services/nft.service';
import { metamaskFactory } from '../../services/metamask.service';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-received',
  templateUrl: './received.component.html',
  styleUrls: ['./received.component.css'],
})
export class ReceivedComponent implements OnInit {
  userAdd: string;
  receivedList;
  tokenId;
  tokenAdd: string;
  constructor(
    private nftService: NftService,
    private router: Router,
    private spinner: NgxSpinnerService,
    private market: MarketplaceService,
    private modalService: NgbModal
  ) {}

  async ngOnInit(): Promise<void> {
    await this.checkMetamask();
    this.userAdd = localStorage.getItem('account_address');
    this.getOfferReceived();
  }

  async getOfferReceived() {
    await this.nftService.getOfferReceived(this.userAdd).subscribe((data) => {
      if (data.success) {
        this.receivedList = data.result;
        console.log(this.receivedList);
      }
    });
  }

  open(content, tokenId, tokenAdd) {
    this.tokenId = tokenId;
    this.tokenAdd = tokenAdd;
    this.modalService.open(content, { size: 'm', centered: true });
  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }

  acceptOffer() {
    this.spinner.show();
    this.market
      .acceptOffer(this.tokenAdd, this.tokenId)
      .then(async (result) => {
        console.log('result', result);
        if (result.timestamp > 0) {
          await this.getOfferReceived();
          this.spinner.hide();
          this.modalService.dismissAll('accept');
          Swal.fire({
            title: 'Success!',
            text: 'The offer is accepted.',
            icon: 'success',
          }).then(function () {
            window.location.reload();
          });
        }
      })
      .catch((err) => {
        console.log(err);
        this.spinner.hide();
        this.modalService.dismissAll('accept');
      });
  }

  checkMetamask() {
    metamaskFactory
      .getMetamaskService()
      .then(async (metamask) => {
        const account = await metamask.getCurrentAccount();
        localStorage.setItem('account_address', account);
        if (account === undefined) {
          this.router.navigate(['login']);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }
}
