import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment';
import { UsersService } from '../services/users.service';

@Injectable()
export class NftService {
  constructor(private http: HttpClient, private userService: UsersService) {}
  token = localStorage.getItem('access_token')
    ? localStorage.getItem('access_token')
    : environment.access_token;

  httpOption = {
    headers: new HttpHeaders({
      Authorization: `Bearer ${this.token}`,
    }),
  };
  //get all animal nft
  getAllAnimalNft(): Observable<any> {
    var httpOptions = {
      headers: new HttpHeaders({
        Authorization: `Bearer ${this.token}`,
      }),
    };
    return this.http.get(environment.apiURL + '/animal/all', httpOptions);
  }
  //get animal marketplace
  getMarketplaceNft(): Observable<any> {
    return this.http.get(
      environment.apiURL + '/animal/marketplace',
      this.httpOption
    );
  }

  //get all game nft
  getAllGameNft(): Observable<any> {
    return this.http.get(environment.apiURL + '/nft/all', this.httpOption);
  }

  getAnimalNftByTokenId(id: number): Observable<any> {
    const body = {
      tokenId: id,
    };

    return this.http.post(
      environment.apiURL + '/animal/byTokenId',
      body,
      this.httpOption
    );
  }
  getGameNftByTokenId(id, token_address, blockchain): Observable<any> {
    let queryParams = new HttpParams();
    queryParams = queryParams.append('tokenId', id);
    queryParams = queryParams.append('token_address', token_address);
    queryParams = queryParams.append('blockchain', blockchain);

    let httpOption = {
      headers: new HttpHeaders({
        Authorization: `Bearer ${this.token}`,
      }),
      params: queryParams,
    };

    return this.http.get(
      environment.apiURL + '/nft/byTokenId-address',
      httpOption
    );
  }

  getNftByUserAddress(body): Observable<any> {
    return this.http.post(
      environment.apiURL + '/animal/byOwner',
      body,
      this.httpOption
    );
  }

  getGameNftByOwner(address): Observable<any> {
    let queryParams = new HttpParams();
    queryParams = queryParams.append('owner', address);

    let httpOption = {
      headers: new HttpHeaders({
        Authorization: `Bearer ${this.token}`,
      }),
      params: queryParams,
    };
    return this.http.get(environment.apiURL + '/nft/byOwner', httpOption);
  }

  getGameNftByPlayerId(id): Observable<any> {
    let queryParams = new HttpParams();
    queryParams = queryParams.append('player_id', id);

    let httpOption = {
      headers: new HttpHeaders({
        Authorization: `Bearer ${this.token}`,
      }),
      params: queryParams,
    };
    return this.http.get(environment.apiURL + '/nft/byPlayerId', httpOption);
  }

  updateViewerById(data): Observable<any> {
    return this.http.patch(
      environment.apiURL + '/nft/viewer',
      data,
      this.httpOption
    );
  }

  updateFavouriteById(data): Observable<any> {
    return this.http.patch(
      environment.apiURL + '/nft/favourite',
      data,
      this.httpOption
    );
  }

  createNft(data): Observable<any> {
    console.log('000', data);
    return this.http.post(
      environment.apiURL + '/nft/create',
      data,
      this.httpOption
    );
  }

  findNft(data): Observable<any> {
    return this.http.post(
      environment.apiURL + '/nft/find',
      { param: data },
      this.httpOption
    );
  }

  updateNftByTokenId(data): Observable<any> {
    return this.http.patch(
      environment.apiURL + '/animal/updateByTokenId',
      data,
      this.httpOption
    );
  }

  updateAnimalSale(data, tokenId, blockchain): Observable<any> {
    return this.http.patch(
      environment.apiURL + 'animal/update/sale/' + tokenId + '/' + blockchain,
      data,
      this.httpOption
    );
  }

  updateGameSale(data, tokenId, tokenAddress, blockchain): Observable<any> {
    return this.http.patch(
      environment.apiURL +
        'nft/update/sale/' +
        tokenId +
        '/' +
        tokenAddress +
        '/' +
        blockchain,
      data,
      this.httpOption
    );
  }

  listToMarket(data): Observable<any> {
    return this.http.patch(
      environment.apiURL + '/nft/list-to-market',
      data,
      this.httpOption
    );
  }

  tradingHIstoryByTokenId(data): Observable<any> {
    return this.http.post(
      environment.apiURL + '/trading/history',
      data,
      this.httpOption
    );
  }

  tradingHistoryBySeller(seller): Observable<any> {
    let queryParams = new HttpParams();
    queryParams = queryParams.append('seller', seller);
    let httpOption = {
      headers: new HttpHeaders({
        Authorization: `Bearer ${this.token}`,
      }),
      params: queryParams,
    };
    return this.http.get(environment.apiURL + '/trading/bySeller', httpOption);
  }

  tradingHistoryByAddress(address): Observable<any> {
    let queryParams = new HttpParams();
    queryParams = queryParams.append('address', address);
    let httpOption = {
      headers: new HttpHeaders({
        Authorization: `Bearer ${this.token}`,
      }),
      params: queryParams,
    };
    return this.http.get(environment.apiURL + '/trading/byAddress', httpOption);
  }

  listToAuction(data): Observable<any> {
    return this.http.patch(
      environment.apiURL + '/nft/list-to-auction',
      data,
      this.httpOption
    );
  }

  makeOffer(data): Observable<any> {
    return this.http.post(
      environment.apiURL + '/offer/made',
      data,
      this.httpOption
    );
  }

  getCurrentOffer(id, token_address, blockchain): Observable<any> {
    let queryParams = new HttpParams();
    queryParams = queryParams.append('tokenId', id);
    queryParams = queryParams.append('token_address', token_address);
    queryParams = queryParams.append('blockchain', blockchain);

    let httpOption = {
      headers: new HttpHeaders({
        Authorization: `Bearer ${this.token}`,
      }),
      params: queryParams,
    };

    return this.http.get(environment.apiURL + '/offer/current', httpOption);
  }

  getOfferHistory(data): Observable<any> {
    return this.http.post(
      environment.apiURL + '/offer-animal/history',
      data,
      this.httpOption
    );
  }

  getOfferMade(data): Observable<any> {
    let queryParams = new HttpParams();
    queryParams = queryParams.append('uid', data);
    let httpOption = {
      headers: new HttpHeaders({
        Authorization: `Bearer ${this.token}`,
      }),
      params: queryParams,
    };
    return this.http.get(environment.apiURL + '/offer/made', httpOption);
  }

  getOfferReceived(data): Observable<any> {
    let queryParams = new HttpParams();
    queryParams = queryParams.append('uid', data);
    let httpOption = {
      headers: new HttpHeaders({
        Authorization: `Bearer ${this.token}`,
      }),
      params: queryParams,
    };
    return this.http.get(environment.apiURL + '/offer/received', httpOption);
  }

  tradingOverView(): Observable<any> {
    return this.http.get(
      environment.apiURL + '/trading/overview',
      this.httpOption
    );
  }

  claimNFT(data): Observable<any> {
    return this.http.post(
      environment.apiURL + '/nft/sign',
      data,
      this.httpOption
    );
  }

  findAnimal(data): Observable<any> {
    return this.http.post(
      environment.apiURL + '/animal/find',
      data,
      this.httpOption
    );
  }

  findEquipment(data): Observable<any> {
    let queryParams = new HttpParams();
    queryParams = queryParams.append('param', data);
    let httpOption = {
      headers: new HttpHeaders({
        Authorization: `Bearer ${this.token}`,
      }),
      params: queryParams,
    };
    return this.http.get(environment.apiURL + '/nft/find', httpOption);
  }
}
