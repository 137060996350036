import { Component, OnInit } from '@angular/core';
import { faSearch } from '@fortawesome/free-solid-svg-icons';
import { Options, LabelType, ChangeContext } from '@angular-slider/ngx-slider';
import { SharedService } from '../../services/shared.service';

@Component({
  selector: 'app-filter',
  templateUrl: './filter.component.html',
  styleUrls: ['./filter.component.css'],
})
export class FilterComponent implements OnInit {
  constructor(private sharedService: SharedService) {}

  showToggle = {};
  myInterval: any;
  faSearch = faSearch;
  minValue: number = 0;
  maxValue: number = 5000;
  options: Options = {
    floor: 0,
    ceil: 5000,
    step: 0.1,
    translate: (value: number, label: LabelType): string => {
      switch (label) {
        case LabelType.Low:
          return '$' + value;
        case LabelType.High:
          return '$' + value;
        default:
          return '$' + value;
      }
    },
  };

  ngOnInit(): void {}

  toggleTag(key) {
    this.showToggle[key] = !this.showToggle[key];
  }

  status: boolean = false;

  clickEvent() {
    this.status = !this.status;
  }

  eventCheck(event) {
    console.log(99, event);
    var params = {
      value: event.target.value,
      status: event.target.checked,
    };
    console.log(99, params);
    this.sharedService.sendClickEvent(params);
  }

  getEvent(e: ChangeContext) {
    this.sharedService.sendSlideEvent(e);
  }
}
