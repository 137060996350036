<section>
  <div
    class="home-banner"
    style="background-image: url({{ collection?.background }});"
  >
    <div class="container h-100">
      <div class="row h-100">
        <div class="col-sm-5 my-auto">
          <div class="slider-content {{ !collection?.isDark ? 'dark' : '' }}">
            <h1>NFT {{ collection?.name }}</h1>
            <h4>{{ collection?.description }}</h4>
          </div>
        </div>
        <div
          class="col-sm-4 col-md-5 col-lg-4  ms-auto my-auto"
          style="display: {{ nftList.length > 0 ? 'flex' : 'none' }}"
        >
          <div class="product-wrapper">
            <div class="zoom-icon" (click)="showQuickview(content1)">
              <fa-icon [icon]="faSearch" class="zoom"></fa-icon>
            </div>
            <a
              href="product/detail/{{ nftList[0]?._id }}"
              class="product-detail-link"
            >
              <div
                class="nft-image banner-image"
                style="
                  max-width: 80%;
                  max-height: 80%;
                  margin: auto;
                  margin-bottom: 10px;
                "
              >
                <img [src]="nftList[0]?.item_image" />
              </div>
              <ul class="nft-ul">
                <li>
                  <fa-icon [icon]="faHeart" class="heart"></fa-icon>
                  <p class="heart-count">{{ nftList[0]?.favourite.length }}</p>
                </li>
                <li>
                  <fa-icon [icon]="faEye" class="heart"></fa-icon>
                  <p class="heart-count">{{ nftList[0]?.viewer.length }}</p>
                </li>
              </ul>
              <div class="nft-detail">
                <h5 class="nft-title">{{ nftList[0]?.item_name }}</h5>
                <a class="nft-author" href="profile/HELP-university">{{
                  nftList[0]?.creator
                }}</a>
              </div>

              <div style="position: absolute; right: 20px; bottom: 20px">
                <img
                  src="assets/images/eth.svg"
                  height="20px"
                  style="display: inline-block; margin-right: 10px"
                />
                <p class="nft-price">{{ nftList[0]?.price }}</p>
              </div>
            </a>
          </div>
        </div>
        <ng-template #content1 let-modal>
          <button
            type="button"
            class="close"
            aria-label="Close"
            (click)="modal.dismiss('Cross click')"
          >
            <span aria-hidden="true">&times;</span>
          </button>
          <div class="zoom-modal">
            <div class="modal-body row">
              <div class="col-lg-5">
                <img
                  [src]="nftList[0]?.item_image"
                  width="100%"
                  style="border-radius: 10px; margin-bottom: 20px"
                />
              </div>
              <div class="col-lg-7">
                <div class="zoom-content">
                  <a
                    href="collection/{{ nftList[0]?.item_name }}"
                    class="zoom-author"
                    >{{ nftList[0]?.item_collection }}</a
                  >
                  <h1 class="zoom-title">{{ nftList[0]?.item_name }}</h1>
                  <p class="zoom-desc">{{ nftList[0]?.item_description }}</p>
                  <hr />
                  <div>
                    <ul class="zoom-ul">
                      <li>
                        <p>
                          Owned by
                          <a href="profile" class="zoom-owner-link">{{
                            nftList[0]?.creator
                          }}</a>
                        </p>
                      </li>
                      <li>
                        <fa-icon [icon]="faEye" class="heart"></fa-icon>
                        <p class="heart-count">
                          {{ nftList[0]?.viewer.length }}
                        </p>
                      </li>
                      <li>
                        <fa-icon [icon]="faHeart" class="heart"></fa-icon>
                        <p class="heart-count">
                          {{ nftList[0]?.favourite.length }}
                        </p>
                      </li>
                    </ul>
                  </div>
                  <div style="margin-bottom: 40px">
                    <img
                      src="assets/images/eth.svg"
                      height="40px"
                      style="display: inline-block; margin-right: 15px"
                    />
                    <p class="zoom-price">{{ nftList[0]?.value }}</p>
                  </div>
                  <div class="row">
                    <div class="col-lg-4 col-sm-6 col-6 ms-auto">
                      <a
                        href="product/detail/{{ nftList[0]?._id }}"
                        class="main-btn btn"
                        (click)="modal.dismiss('Cross click')"
                      >
                        View More</a
                      >
                    </div>
                    <div class="col-lg-4 col-sm-6 col-6">
                      <a class="sub-btn btn"> Buy Now</a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </ng-template>
      </div>
    </div>
  </div>
</section>
<section>
  <div class="container">
    <div class="row">
      <div class="col-md-12 col-lg-3">
        <app-filter></app-filter>
      </div>
      <div class="col-md-12 col-lg-9">
        <div class="row" style="padding: 20px 0">
          <div class="col-sm-12 col-lg-4">
            <div class="result-count">{{ nftList.length }} Results</div>
          </div>
          <div class="col-5 col-sm-5 col-lg-3 ms-auto mb-4">
            <select class="select-option" aria-label="Default select example">
              <option value="1" selected>Recently Listed</option>
              <option value="2">Recently Created</option>
              <option value="3">Recently Sold</option>
            </select>
          </div>
          <div class="col-5 col-sm-2 view-btn mb-4">
            <div (click)="gridRow(1)">
              <fa-icon [icon]="faTh" class="grid-row"></fa-icon>
            </div>
            <div (click)="gridRow(0)">
              <fa-icon [icon]="faList" class="grid-row"></fa-icon>
            </div>
          </div>
        </div>
        <div class="row">
          <ng-container *ngIf="!nftList">
            <div>Loading NFTList ...</div>
          </ng-container>
          <ng-container *ngIf="nftList && nftList.length == 0">
            <div class="text-center">NFT is not available.</div>
          </ng-container>
          <div
            class="{{
              isGrid ? 'col-sm-6 col-md-6 col-lg-4 col-xl-4' : 'col-sm-12'
            }}"
            style="margin-bottom: 30px"
            *ngFor="let nft of nftList"
          >
            <div class="product-wrapper">
              <div class="zoom-icon" (click)="showQuickview(content)">
                <fa-icon [icon]="faSearch" class="zoom"></fa-icon>
              </div>
              <a
                href="product/detail/{{ nft?._id }}"
                class="product-detail-link"
              >
                <div class="row">
                  <div
                    class="{{
                      isGrid ? 'col-sm-12' : 'col-lg-4 col-sm-6  col-4'
                    }}"
                  >
                    <div class="nft-image">
                      <img [src]="nft?.item_image" />
                    </div>
                  </div>
                  <div class="{{ isGrid ? 'col-sm-12' : 'col-lg-8 col-sm-6' }}">
                    <ul class="nft-ul">
                      <li>
                        <fa-icon [icon]="faHeart" class="heart"></fa-icon>
                        <p class="heart-count">
                          {{ nft?.favourite.length }}
                        </p>
                      </li>
                      <li>
                        <fa-icon [icon]="faEye" class="heart"></fa-icon>
                        <p class="heart-count">
                          {{ nft?.viewer.length }}
                        </p>
                      </li>
                    </ul>
                    <div class="nft-detail">
                      <h5 class="nft-title">{{ nft?.item_name }}</h5>
                      <a class="nft-author" href="profile/HELP-university">
                        <!-- {{ nft?.creator }} -->
                      </a>
                      <p
                        style="display: {{
                          isGrid ? 'none' : 'block'
                        }}; color: #707a83 ; font-size: 14px"
                      >
                        {{ nft?.item_description }}
                      </p>
                    </div>
                    <div style="position: absolute; right: 20px; bottom: 20px">
                      <img
                        src="assets/images/eth.svg"
                        height="20px"
                        style="display: inline-block; margin-right: 10px"
                      />
                      <p class="nft-price">{{ nft?.price }}</p>
                    </div>
                  </div>
                </div>
              </a>
            </div>

            <ng-template #content let-modal>
              <button
                type="button"
                class="close"
                aria-label="Close"
                (click)="modal.dismiss('Cross click')"
              >
                <span aria-hidden="true">&times;</span>
              </button>
              <div class="zoom-modal">
                <div class="modal-body row">
                  <div class="col-lg-5">
                    <img
                      [src]="nft?.item_image"
                      width="100%"
                      style="border-radius: 10px; margin-bottom: 20px"
                    />
                  </div>
                  <div class="col-lg-7">
                    <div class="zoom-content">
                      <a
                        href="collection/{{ nft?.collection }}"
                        class="zoom-author"
                        >{{ nft?.item_collection }}</a
                      >
                      <h1 class="zoom-title">{{ nft?.item_name }}</h1>
                      <p class="zoom-desc">{{ nft?.item_description }}</p>
                      <hr />
                      <div>
                        <ul class="zoom-ul">
                          <li>
                            <p>
                              Owned by
                              <a href="profile" class="zoom-owner-link">{{
                                nft?.creator
                              }}</a>
                            </p>
                          </li>
                          <li>
                            <fa-icon [icon]="faEye" class="heart"></fa-icon>
                            <p class="heart-count">{{ nft?.viewer.length }}</p>
                          </li>
                          <li>
                            <fa-icon [icon]="faHeart" class="heart"></fa-icon>
                            <p class="heart-count">
                              {{ nft?.favourite.length }}
                            </p>
                          </li>
                        </ul>
                      </div>
                      <div style="margin-bottom: 40px">
                        <img
                          src="assets/images/eth.svg"
                          height="40px"
                          style="display: inline-block; margin-right: 15px"
                        />
                        <p class="zoom-price">{{ nft?.price }}</p>
                      </div>
                      <div class="row">
                        <div class="col-lg-4 col-sm-6 col-6 ms-auto">
                          <a
                            href="product/detail/{{ nft?._id }}"
                            class="main-btn btn"
                            (click)="modal.dismiss('Cross click')"
                            >View More</a
                          >
                        </div>
                        <div class="col-lg-4 col-sm-6 col-6">
                          <a class="sub-btn btn"> Buy Now</a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </ng-template>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
