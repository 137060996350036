import { Injectable } from '@angular/core';
import { ethers } from 'ethers';
import { environment } from 'src/environments/environment';
declare let window: any
@Injectable({
  providedIn: 'root'
})
export class ScEventsService {

  constructor() { }

  startListener() {
    const provider = new ethers.providers.Web3Provider(window.ethereum);
    const marketContract = new ethers.Contract(environment.SVV_MARKETPLACE_ADDRESS, environment.SVV_ABI.MARKETPLACE, provider);

    console.log('Listening events...');
    
    marketContract.on('Sold', (_tokenAddress, _tokenId, _seller, _buyer, _price) => {
      console.log({
        eventId: "Sold",
        tokenAddress: _tokenAddress,
        tokenId: _tokenId,
        seller: _seller,
        buyer: _buyer,
        price: _price
      });
    })

    marketContract.on('PriceUpdated', (_tokenAddress, _tokenId, _seller, _price) => {
      console.log({
        eventId: 'PriceUpdated',
        tokenId: _tokenId,
        seller: _seller,
        price: _price
      });
    })

    marketContract.on('OfferSubmitted', (_tokenAddress, _tokenId, _buyer, _price) => {
      console.log({
        eventId: 'OfferSubmitted',
        tokenAddress: _tokenAddress,
        tokenId: _tokenId,
        buyer: _buyer,
        price: _price
      });
    });


    marketContract.on('OfferCanceled', (_tokenAddress, _tokenId, _buyer) => {
      console.log({
        eventId: 'OfferCanceled',
        tokenAddress: _tokenAddress,
        tokenId: _tokenId,
        buyer: _buyer
      });
    });

    marketContract.on('OfferAccepted', (_tokenAddress, _tokenId, _buyer) => {
      console.log({
        eventId: 'OfferAccepted',
        tokenAddress: _tokenAddress,
        tokenId: _tokenId,
        buyer: _buyer
      });
    })
  }
}
